import React from "react";
import Image from "../../components/Image";
import Obfuscate from "react-obfuscate";

const Contacts = ({ page, contacts }) => {
  return (
    <div className="my-12 md:my-16">
      <h1 className="text-3xl lg:text-6xl font-bold">{page.title}</h1>

      <div
        className="my-12"
        dangerouslySetInnerHTML={{ __html: page.contactPage.description }}
      ></div>

      <div className="flex mt-16 gap-8 md:gap-24">
        {contacts.map((contact, index) => {
          return (
            <div key={index}>
              <div className="w-32 md:w-40 h-32">
                <Image image={contact.image} />
              </div>
              <h4 className="font-bold">{contact.name}</h4>
              <div className="font-bold">
                <span className="text-teal">m. </span>
                <Obfuscate tel={contact.mobile}>{contact.mobile}</Obfuscate>
              </div>

              <Obfuscate
                email={contact.email}
                className="text-teal underline hover-hover:hover:text-white"
              >
                Email
              </Obfuscate>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Contacts;
